
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";

import UploadSekolah from "@/components/1crm/sekolah/upload-Sekolah.vue";

import DownloadSekolah from "@/components/1crm/sekolah/download-Sekolah.vue";

// Import loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// end import loading overlay

import Multiselect from "@vueform/multiselect";

import moment from "moment";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    Field,
    Loading, //loading overlay
    Multiselect,
    UploadSekolah,
    DownloadSekolah,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: null,
          deleteTime: null,
          name: "",

          phone: "",
          email: "",
          schoolType: "",
          picProduct: "",
          initial: "",
          picName: "",
          picPhone: "",
          picEmail: "",
          referenceFrom: "",
          bankAreaId: null,
          bankBranchId: null,
          bankData: [],
          implementatorData: [],
          fundingId: null,
          fundingName: "",
          status: "",
          targetLive: "6",

          note: "",
          salesArea: "",
          isMigratePsp: true,
          isMigratePpdb: false,
          institutionCentralId: null,
          institutionBranchId: null,
          npsn: null,
          usedPin: false,
          schoolFeature: {
            ppdb: "",
          },

          selfMapping: false,
          limitCard: 0,
          startMappingDate: null,
          endMappingDate: null,
          _id: "",
          studentCount: 0,
          listImplementatorName: "",
          listBankName: "",
          creatorName: "",
          salesAreaName: "",
        },
      ],
      listBank: [],
      listArea: [],
      listCabang: [],
      paging: {
        page: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      aksescombostatus: false,
      name: "",
      fundingName: "",
      salesArea: "",
      implementator: "",
      schoolStatus: "",
      isMigratePsp: "",
      status: "",
      idBank: null,
      schoolId: "",
      idArea: "",
      cekArea: "",
      idCabang: "",
      cekCabang: "",
      role: "",
      user_sales_area: [],
      user_implementator: [],
      comboSchoolStatus: [
        {
          name: "Leads",
          _id: "Leads",
        },
        {
          name: "Process",
          _id: "Process",
        },
        {
          name: "Closing",
          _id: "Closing",
        },
        {
          name: "Cancel",
          _id: "Cancel",
        },
        {
          name: "Live",
          _id: "Live",
        },
      ],
      comboIsMigratePsp: [
        {
          name: "Sudah",
          _id: "true",
        },
        {
          name: "Belum",
          _id: "false",
        },
      ],
      comboIsMigratePspstatus: [
        {
          name: "Closing",
          _id: "Closing",
        },
        {
          name: "Live",
          _id: "Live",
        },
      ],
      sorting: "sort=createTime&dir=-1",
    };
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);

    this.getListBank();
    this.combo_user_sales_area();
    this.combo_user_implementator();

    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.cekRoleAkses();
    this.idBank = JSON.parse(localStorage.getItem("idBankSelected")!);

    if (this.idBank) {
      this.getData(this.paging.size, this.paging.page);
    } else {
      this.getData(this.paging.size, this.paging.page);
    }
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksescombostatus = false;
          break;
        case "SALES_LEADER":
          this.aksescombostatus = false;

          break;
        case "CS_LEADER":
          this.aksescombostatus = false;

          break;
        case "CS_MEMBER":
          this.aksescombostatus = false;

          break;
        case "SALES_AREA":
          this.aksescombostatus = false;

          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksescombostatus = true;

          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksescombostatus = true;

          break;
        case "ADMIN_LEGAL":
          this.aksescombostatus = false;

          break;
      }
    },
    onChangeSekolah() {
      this.getData(this.paging.size, this.paging.page);
    },

    getData(size, page) {
      // loading overlay tampil
      this.isLoading = true;

      localStorage.setItem("idBankSelected", JSON.stringify(this.idBank));

      const toast = useToast();

      if (this.role == "CS_LEADER" || this.role == "CS_MEMBER") {
        this.schoolStatus = "Live";
      }
      let search = "";
      if (this.idBank) {
        search = "bank=" + this.idBank + "&";
      } else {
        search;
      }

      let search1 = "";
      if (this.name) {
        search1 = "name=" + this.name + "&";
      } else {
        search1;
      }

      let search2 = "";
      if (this.fundingName) {
        search2 = "fundingName=" + this.fundingName + "&";
      } else {
        search2;
      }

      let search3 = "";
      if (this.salesArea) {
        search3 = "salesArea=" + this.salesArea + "&";
      } else {
        search3;
      }

      let search4 = "";
      if (this.schoolStatus) {
        search4 = "schoolStatus=" + this.schoolStatus + "&";
      } else {
        search4;
      }

      let search5 = "";
      if (this.isMigratePsp) {
        search5 = "isMigratePsp=" + this.isMigratePsp + "&";
      } else {
        search5;
      }
      let search6 = "";
      if (this.status) {
        search6 = "schoolStatus=" + this.status + "&";
      } else {
        search6;
      }
      let search7 = "";
      if (this.implementator) {
        search7 = "implementatorId=" + this.implementator;
      } else {
        search7;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/all?" +
          search +
          search1 +
          search2 +
          search3 +
          search4 +
          search5 +
          search6 +
          search7 +
          "&page=" +
          page +
          "&size=" +
          size +
          "&" +
          this.sorting
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.tableData = [];
            this.isLoading = false;
          }
        });
    },

    getListBank() {
      const toast = useToast();

      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks")
        .then(({ data }) => {
          this.listBank = data;
        })
        .catch((errors) => {
          toast.error(errors.response.data.detail);
        });
    },

    getListArea() {
      this.getData(this.paging.size, this.paging.page);
      const toast = useToast();
      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" + this.idBank + "/combo_areas"
      )
        .then(({ data }) => {
          this.cekArea = "isi";
          this.listArea = data;
        })
        .catch((errors) => {
          this.cekArea = "";
          toast.error(errors.response.data.detail);
        });
    },

    getListCabang() {
      this.getData(this.paging.size, this.paging.page);
      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" +
          this.idBank +
          "/" +
          this.idArea +
          "/combo_branchs"
      )
        .then(({ data }) => {
          this.cekCabang = "isi";
          this.listCabang = data;
        })
        .catch((errors) => {
          this.cekCabang = "";
          toast.error(errors.response.data.detail);
        });
    },

    pilihBank(event) {
      if (event) {
        this.idBank = event;
      } else {
        this.idBank = null;
      }
      this.getData(this.paging.size, this.paging.page);
    },

    pilihSalesArea(event) {
      if (event) {
        this.salesArea = event;
      } else {
        this.salesArea = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    pilihImplementator(event) {
      if (event) {
        this.implementator = event;
      } else {
        this.implementator = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    pilihSchoolStatus(event) {
      if (event) {
        this.schoolStatus = event;
      } else {
        this.schoolStatus = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    pilihIsMigratePsp(event) {
      if (event) {
        this.isMigratePsp = event;
      } else {
        this.isMigratePsp = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },
    pilihIsMigratePspstatus(event) {
      if (event) {
        this.status = event;
      } else {
        this.status = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },
    handleDeleteMigrationTrue(index, item) {
      Swal.fire({
        title: "Tidak dapat menghapus sekolah yang sudah termigrasi di PSP!",
        text: ``,
        type: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {});
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },

    deleteRow(item) {
      this.isLoading = true;
      const toast = useToast();

      ApiService.delete("crmv2/main_school/school/school/" + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error("Gagal Hapus Sekolah");
          this.isLoading = false;
        });
    },

    migrationTrue(index, item) {
      Swal.fire({
        title: "Sekolah sudah terdaftar di Admin PSP",
        text: `Silahkan login ke Web PSP untuk melihat data Sekolah`,
        type: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {});
    },

    migrationSchool(index, item) {
      Swal.fire({
        title: "Apakah anda yakin akan menambahkan sekolah ke admin PSP ?",
        text: ``,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.migrationSchool2(item);
        }
      });
    },

    migrationSchool2(item) {
      this.isLoading = true;
      const toast = useToast();

      ApiService.postWithoutSlug(
        "crmv2/main_school/school/school/migrate/" + item._id
      )
        .then((res) => {
          toast.success("Berhasil Daftar Sekolah Ke PSP");
          this.getData(this.paging.size, this.paging.page);

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
    migrationPPDB(index, item) {
      Swal.fire({
        title:
          "Apakah anda yakin akan melakukan migrasi sekolah untuk menggunakan fitur PPDB?",
        text: ``,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.postMigrationPPDB(item);
        }
      });
    },
    postMigrationPPDB(item) {
      this.isLoading = true;
      const toast = useToast();

      ApiService.postWithoutSlug(
        "crmv2/main_school/school/school/migrate_to_ppdb/" + item._id
      )
        .then((res) => {
          toast.success("Migrasi PPDB Berhasil");
          this.getData(this.paging.size, this.paging.page);

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else if (
            e.response.status == 400 &&
            e.response.data.detail ===
              `Duplikat data. Id '${item._id}' telah terdaftar`
          ) {
            toast.error("Sekolah Sudah Terdaftar di PPDB");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
    combo_user_sales_area() {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_LEADER "
      ).then((response) => {
        this.user_sales_area = response.data;
        ApiService.getWithoutSlug(
          "crmv2/main_user/user/combo_user?userRole=SALES_AREA "
        ).then((res) => {
          var a = res.data;
          var eng = [{}];
          eng = this.user_sales_area;
          a.forEach((u) => {
            eng.push({
              name: u.name,
              _id: u._id,
            });
          });
        });
      });
    },

    combo_user_implementator() {
      ApiService.getWithoutSlug(
        "crmv2/main_user/implementator/combo_user"
      ).then((response) => {
        this.user_implementator = response.data;
      });
    },

    formatDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    sortingFunc(e) {
      this.sorting = e;
      return this.getData(this.paging.size, this.paging.page);
    },
  },
});
