
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { string } from "yup/lib/locale";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";

interface items {
  listSekolah: any;
  disableButton: boolean;

  role: string;

  comboSchoolStatus: any;
  schoolStatus: string;
}

export default defineComponent({
  name: "new-target-modal",
  components: {
    Loading,
    Multiselect,
    Field,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const downloadModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      listSekolah: [],
      disableButton: false,

      role: "",

      comboSchoolStatus: [
        {
          name: "Leads",
          _id: "Leads",
        },
        {
          name: "Process",
          _id: "Process",
        },
        {
          name: "Closing",
          _id: "Closing",
        },
        {
          name: "Cancel",
          _id: "Cancel",
        },
        {
          name: "Live",
          _id: "Live",
        },
      ],

      schoolStatus: "",
    });

    const callModal = () => {
      const modal = new Modal(
        document.getElementById("kt_modal_download_sekolah")
      );
      modal.show();
    };

    const batalDownload = () => {
      items.schoolStatus = "";
    };

    const downloadSekolah = () => {
      if (items.schoolStatus) {
        isLoading.value = true;

        ApiService.getDownload(
          "crmv2/main_school/school/school/download_school/" +
            items.schoolStatus
        )
          .then((response) => {
            // this.handleBlobResponse(response.data, item, extension);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            var extension = "xlsx";
            link.setAttribute(
              "download",
              "Data Sekolah Status = " + items.schoolStatus + "." + extension
            ); // nama file dan extension sesuaikan dengan file yang di download
            document.body.appendChild(link);
            link.click();

            items.schoolStatus = "";
            toast.success("Sukses Unduh File");
            hideModal(downloadModalRef.value);
            isLoading.value = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error("Gagal Unduh File");
            isLoading.value = false;
          });
      } else {
        isLoading.value = true;

        ApiService.getDownload(
          "crmv2/main_school/school/school/download_school/all"
        )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            var extension = "xlsx";
            link.setAttribute(
              "download",
              "Data Sekolah Semua Status." + extension
            ); // nama file dan extension sesuaikan dengan file yang di download
            document.body.appendChild(link);
            link.click();

            items.schoolStatus = "";
            toast.success("Sukses Unduh File");
            hideModal(downloadModalRef.value);
            isLoading.value = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error("Gagal Unduh File");
            isLoading.value = false;
          });
      }
    };

    const pilihSchoolStatus = (event) => {
      if (event) {
        items.schoolStatus = event;
      } else {
        items.schoolStatus = "";
      }
    };

    onMounted(() => {
      items.role = JSON.parse(localStorage.getItem("user_account")!).role;
    });

    return {
      loading,
      formRef,
      downloadModalRef,
      items,

      downloadSekolah,
      batalDownload,
      callModal,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

      pilihSchoolStatus,
    };
  },
});
